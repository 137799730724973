var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"req-temp-container"},[_c('vs-card',{staticClass:"mt-6"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"flex items-center"},[_c('h3',{staticClass:"font-medium"},[_vm._v("Bank accounts")])])]),_c('div',[_c('vs-row',_vm._l((_vm.bankAccounts),function(bank,key){return _c('vs-col',{key:key,staticClass:"p-2",attrs:{"vs-w":"4"}},[_c('vs-card',{staticClass:"mt-6 bankCard"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"items-center"},[_c('h5',{staticClass:"font-medium mb-2"},[_vm._v(_vm._s(bank.accountName))]),_c('p',[_c('span',[_vm._v("Account ID: "+_vm._s(bank.bankAccountId))])])])]),_c('div',[_c('div',{staticClass:"flex mb-4"},[(
                    bank.isUsedForSettlement === true &&
                    bank.isUsedForBilling === false
                  )?_c('p',{staticClass:"darkBlue font-light"},[_vm._v(" This account is used for settlement. ")]):_vm._e(),(
                    bank.isUsedForBilling === true &&
                    bank.isUsedForSettlement === false
                  )?_c('p',{staticClass:"darkBlue font-light"},[_vm._v(" This account is used for billing. ")]):_vm._e(),(
                    bank.isUsedForBilling === true &&
                    bank.isUsedForSettlement === true
                  )?_c('p',{staticClass:"darkBlue font-light"},[_vm._v(" This account is used for settlement and billing. ")]):_vm._e()]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("BSB")]),_c('p',{staticClass:"darkBlue font-normal"},[_vm._v(" "+_vm._s(((bank.bsb[0]) + "-" + (bank.bsb[1])))+" ")])]),_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Account number")]),_c('p',{staticClass:"darkBlue font-normal"},[_vm._v(_vm._s(bank.accountNumber))])])]),_c('div',{staticClass:"flex mt-3"},[_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"vs-input--label"},[_vm._v("Display name")]),_c('vs-input',{staticClass:"darkBlue font-normal",attrs:{"name":"displayName"},on:{"input":_vm.checkForm},model:{value:(bank.displayName),callback:function ($$v) {_vm.$set(bank, "displayName", $$v)},expression:"bank.displayName"}})],1)])])])],1)}),1),_c('vs-row',{staticClass:"block"},[_c('div',{staticClass:"button-section"},[_c('vs-button',{directives:[{name:"round",rawName:"v-round"}],staticClass:"primary lg:mr-8 mr-4",attrs:{"disabled":_vm.isValidField,"size":"medium"},on:{"click":function($event){return _vm.updateDisplayName()}}},[_vm._v("Save")]),_c('div',{staticClass:"mid-blue",on:{"click":function($event){return _vm.$router.push({ name: 'settings' })}}},[_c('u',[_vm._v("Cancel")])])],1)])],1)]),_c('leave-popup',{attrs:{"popup-active":_vm.popupActive},on:{"handleClick":_vm.popActions}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }